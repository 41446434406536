import React from "react"

import HundsunAyersIcon from "../../images/HundsunAyers.png"
import KMBIcon from "../../images/KMB.jpg"
import SapNinIcon from "../../images/SapNin.jpg"
import OrderEatIcon from "../../images/OrderEat.png"
import ITinHKIcon from "../../images/ITinHK.png"
import HKUSTIcon from "../../images/HKUST.png"
import "./Pages.css"


const Project = props => (
    <div className="pageContainer pagePadding projectBackground">
        <p className="title">Projects</p>

        <div className="content">
            <div className="flex-container" style={{ flexWrap: "wrap", alignItems: "center" }}>
                <div className="flex-container project-card-new">
                    <div className="project-card-image">
                        <img src={HundsunAyersIcon} alt="Hundsun Ayers Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>Hundsun Ayers Trading System</h1>
                        <p>Trading Mobile App mainly targeted and not limited to HKEX that built with Flutter</p>
                    </div>
                </div>

                <div className="flex-container project-card-new">
                    <div className="project-card-image">
                        <img src={KMBIcon} alt="Kowloon Motor Bus Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>Kowloon Motor Bus</h1>
                        <p>Duty Registration System maintaince and enhancement with Powerbuilder and SQL</p>
                    </div>
                </div>

                <div className="flex-container project-card-new">
                    <div className="project-card-image">
                        <img src={SapNinIcon} alt="Sap Nin Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>SapNin</h1>
                        <p>Information App that built with React Native</p>
                        <a className="button" href="https://play.google.com/store/apps/details?id=com.sapninlimitedco.sapnin&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">View App</a>
                    </div>
                </div>

                <div className="flex-container project-card-new">
                    <div className="project-card-image">
                        <img src={OrderEatIcon} alt="Order Eat Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>OrderEat</h1>
                        <p>Food ordering system contains Client App, Business App and Admin Website that built with React, React Native, ExpressJs, Socket.IO and MongoDB</p>
                        <a className="button" href="https://youtu.be/Wba5NTLquDY" target="_blank" rel="noopener noreferrer">View Demo</a>
                    </div>
                </div>

                <div className="flex-container project-card-new">
                    <div className="project-card-image">
                        <img src={ITinHKIcon} alt="IT in Hong Kong Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>ITinHK</h1>
                        <p>Website monitor Jobsdb's IT jobs and giving some statistics that built with jQuery</p>
                    </div>
                </div>

                <div className="flex-container project-card-new">
                    <div className="project-card-image" style={{ padding: 16, background: "white" }}>
                        <img src={HKUSTIcon} alt="HKUST Logo" />
                    </div>
                    <div className="project-card-text">
                        <h1>Common Course Review System 2.0</h1>
                        <p>HKUST internal system that built with AngularJS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Project;